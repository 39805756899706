import React from "react"
import Icon from "@mdi/react"
import { mdiWeb, mdiLaptop, mdiDownload, mdiGithub } from "@mdi/js"

const CalToActionBtns = ({ isDark = false }: { isDark?: boolean }) => (
  <div className={`call-to-action-btns ${isDark ? "isDark" : ""}`}>
    <a href="https://app.super-productivity.com">
      <Icon path={mdiWeb} />
      <span>web app</span>
    </a>
    <a href="download">
      <Icon path={mdiLaptop} />
      <span>download</span>
    </a>
    <a
      href="https://github.com/johannesjo/super-productivity"
      className="hide-xs"
    >
      <Icon path={mdiGithub} />
      <span>hack</span>
    </a>
  </div>
)
export default CalToActionBtns
