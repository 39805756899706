import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../scss/global.scss"
import "./index.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Icon from "@mdi/react"
import { mdiWeb, mdiLaptop } from "@mdi/js"

import iconWhite from "../assets/img/ico-white.svg"
import CalToActionBtns from "../components/call-to-action-btns"
import Share from "../components/share"
import Header from "../components/header"

const IndexPage = ({ data: { allImageContent, allDataYaml } }: any) => (
  <Layout isHideHeader={true}>
    <SEO
      title="Super Productivity – a to do list to get your head free"
      description="Made for developer & freelancer, Super Productivity helps to structure, organize & share your work. Learn more about features like time tracking & Jira connect."
    />

    <div className="landing-page">
      <section className="intro-wrapper">
        <Header />
        <div className="intro-gradient-wrapper">
          <div className="sky-gradient" />
          <div className="intro-text-wrapper">
            <h1 className="hero-title">
              Be more productive with
              <br /> Super Productivity
            </h1>
            <img src={iconWhite} alt="Logo" className="logo" />
          </div>
        </div>

        <div className="call-to-action-wrapper">
          <CalToActionBtns isDark={true} />
        </div>
      </section>

      <div className="inner-main">
        <section className="features-wrapper">
          <div className="container">
            <div className="features">
              {allDataYaml.nodes
                .find((n: any) => !!n.features)
                .features.map((item: any, i: number) => {
                  // fetches the image based on the value that that is set on the appropriate json element
                  const imgs = allImageContent.edges.find(
                    (element: any) =>
                      element.node.fluid.originalName === item.img.src
                  )
                  return (
                    <div key={i} className="feature">
                      <div className="text">
                        <h2>{item.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: item.text }} />
                        {item.url && <a href={item.url}>Read more</a>}
                      </div>
                      <Img fluid={imgs.node.fluid} />
                    </div>
                  )
                })}
            </div>
          </div>
        </section>

        <section className="free-your-mind block-blue">
          <div className="sky-gradient" />
          <div className="container above-sky">
            <h2 className="center">Simply free your mind</h2>
            <p>
              Put a task into your project for today or schedule it to another
              day to keep your head free.
            </p>
          </div>

          <div className="call-to-action-wrapper">
            <CalToActionBtns isDark={false} />
          </div>
        </section>

        <section className="testimonials">
          <div className="container">
            <h2 className="center">What others say</h2>

            {allDataYaml.nodes
              .find((n: any) => !!n.home)
              .home.testimonials.map((item: any, i: number) => {
                const imgs = allImageContent.edges.find(
                  (element: any) =>
                    element.node.fluid.originalName === item.img.src
                )
                return (
                  <div className="testimonial" key={i}>
                    <Img fluid={imgs.node.fluid} />
                    <blockquote>
                      <p>{item.quote}</p>
                      <footer>
                        —{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: item.author }}
                        />
                      </footer>
                    </blockquote>
                  </div>
                )
              })}
          </div>
        </section>

        {/*<section className="yt-wrapper ">*/}
        {/*  <div className="container">*/}
        {/*    <h2 className="center">Super Productivity at a glance</h2>*/}
        {/*    <div className="yt-video">*/}
        {/*      <iframe*/}
        {/*        loading="lazy"*/}
        {/*        title="Super Productivity introduction video"*/}
        {/*        width="100%"*/}
        {/*        height="480"*/}
        {/*        src="https://www.youtube-nocookie.com/embed/_mvMXWLsL-8"*/}
        {/*        frameBorder="0"*/}
        {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*        allowFullScreen*/}
        {/*      ></iframe>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section className="share-section">
          <h2 className="center">Spread the word!</h2>
          <Share />
        </section>

        <section className="choose block-blue">
          <div className="sky-gradient"></div>
          <div className="container">
            <div className="web ">
              <a href="https://app.super-productivity.com">
                <Icon path={mdiWeb} size={1} />
                <div className="title-text">Go to Web App</div>
              </a>

              <ul>
                <li>Access everywhere</li>
                <li>Clean and simple task organizing interface</li>
                <li>
                  Simple time tracking and eas creation of time and work sheets
                </li>
                <li>Full Jira Integration via Chrome Extension</li>
                <li>GitHub issue integration</li>
                <li>Full Control over your data</li>
              </ul>
            </div>
            <div className="desktop ">
              <a href="download">
                <Icon path={mdiLaptop} size={1} />
                <div className="title-text">Download for Desktop</div>
              </a>

              <ul>
                <li>Clean and simple task organizing interface</li>
                <li>
                  Simple time tracking and eas creation of time and work sheets
                </li>
                <li>Full Jira Integration</li>
                <li>GitHub issue integration</li>
                <li>Integrated within your desktop</li>
                <li>Full Control over your data</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 534) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
    allDataYaml {
      nodes {
        home {
          testimonials {
            author
            quote
            img {
              src
            }
          }
        }

        features {
          title
          text
          url
          img {
            src
          }
        }
      }
    }
  }
`
